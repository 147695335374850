@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.line {
    @apply absolute left-[-100px] h-px -translate-y-2/4 right-full top-2/4;

    background: white;
}

@font-face {
    font-family: 'Boogie Boys';
    src: url('./assets/fonts/boogie_boys/BoogieBoys.ttf');
}

@font-face {
    font-family: 'American Typewriter';
    src: url('./assets/fonts/American Typewriter Regular.ttf');
}

@font-face {
    font-family: 'Anton';
    src: url("./assets/fonts/Anton-Regular.ttf");
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./assets/fonts/BebasNeue-Regular.ttf');
}

@font-face {
    font-family: 'Felt Tip Woman';
    src: url("./assets/fonts/Felt Tip Woman.ttf");
}

@font-face {
    font-family: 'Graun';
    src: url("./assets/fonts/GRAUN.TTF");
}

@font-face {
    font-family: 'VTKS Distress';
    src: url('./assets/fonts/vtks distress.ttf');
}

@font-face {
    font-family: 'Sister Spray';
    src: url('./assets/fonts/Sister Spray.ttf');
}

@font-face {
    font-family: "Sex Pistols";
    src: url("./assets/fonts/Sex_Pistols.ttf");
}


@font-face {
    font-family: "Railway Gank";
    src: url("./assets/fonts/RailwayGank.ttf");
}

@font-face {
    font-family: "Beastie";
    src: url("assets/fonts/beastie.ttf");
}

@font-face {
    font-family: "Beastie Bold";
    src: url("./assets/fonts/Beastie Bold.ttf");
}

* {
    font-family: 'Montserrat', sans-serif;
}
body {
    /*@apply bg-black;*/

}

.outlined {
    -webkit-text-stroke: 1px black; /* Adjust the stroke width and color as needed */
    color: white; /* Change this to the background color or any other color if you prefer */
}

svg {
    width: 100%;
    height: 100%;
}

textPath {
    font-size: 18px;
}

.justify-center-text {
    text-align: justify;
    text-align-last: center;
}
